









































import Vue, { PropType } from "vue";

import ChooseTicketCard from "@/components/Ticket/ChooseTicketCard.vue";
import TTicketCounter from "@/components/core/TTicketCounter.vue";
import Sector from "@/types/Sector";
import Event from "@/types/Event";

export default Vue.extend({
  name: "FormChooseTicket",

  components: {
    ChooseTicketCard,
    TTicketCounter,
  },

  props: {
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
    value: {
      type: Array as PropType<Sector[]>,
      default: () => [],
    },
  },

  computed: {
    loading(): boolean {
      return this.$loader.loading();
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    sectors: {
      get(): Sector[] {
        return this.value;
      },
      set(value: Sector[]) {
        this.$emit("input", value);
      },
    },
    selectedTicketAmount(): number {
      let total = 0;
      for (const sector of this.sectors) {
        for (const section of sector.sections) {
          total += section.tipos_ingresso.reduce((acc, t) => acc + t.quantidade * t.passport_amount, 0);
        }
      }

      return total;
    },
  },
  methods: {
    hasTicketSelected(): boolean {
      for (const sector of this.sectors) {
        for (const section of sector.sections) {
          if (section.tipos_ingresso.reduce((acc, t) => acc + t.quantidade, 0) > 0) {
            return true;
          }
        }
      }

      return false;
    },
    validate(): boolean {
      if (!this.$store.state.user.logged) {
        let logged = false;

        this.$dialog.show("/login", {
          onClose: () => {
            logged = this.$store.state.user.logged && !!this.$store.state.user.data.id;
          },
        });

        if (!logged) throw new Error("Você precisa estar logado para prosseguir.");
      }

      if (!this.hasTicketSelected()) {
        throw new Error("Você precisa escolher ao menos um ingresso.");
      }

      return true;
    },
    async submit(): Promise<boolean> {
      await this.$recaptchaLoaded();

      const token = await this.$recaptcha("submit");

      await this.$store.dispatch("cart/createReservation", { event_id: this.event.id, token });

      this.$store.commit("cart/startTimer");

      return true;
    },
  },
});
