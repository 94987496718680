
























































import Vue, { PropType } from "vue";

import TNumberInput from "@/components/core/TNumberInput.vue";
import TicketType from "@/types/TicketType";

import formatCurrency from "@/utils/formatCurrency";
import Event from "@/types/Event";

export default Vue.extend({
  name: "ChooseTicketCardItem",

  components: {
    TNumberInput,
  },

  props: {
    ticket: {
      type: Object as PropType<TicketType>,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
    },
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
    globalSelectedTickets: {
      type: Number,
      required: true,
    },
  },

  computed: {
    hasLimiter(): boolean {
      return this.ticket.quantidade_venda_permitida !== null || this.event.quantidade_ingresso_venda_online !== null;
    },
    soldOut(): boolean {
      const available = this.ticket.quantidade_venda_permitida;

      if (available === null) return false;

      const occupied = this.ticket.quantidade_venda_finalizada + this.ticket.quantidade_reservada;

      return available <= occupied;
    },
    remainingAmount(): number {
      if (!this.hasLimiter) return 99999;

      const a = this.value + this.event.quantidade_ingresso_venda_online - this.globalSelectedTickets;

      if (this.ticket.quantidade_venda_permitida === null) {
        return a;
      }

      const b =
        this.ticket.quantidade_venda_permitida -
        (this.ticket.quantidade_venda_finalizada + this.ticket.quantidade_reservada);

      return Math.min(a, b);
    },
    hasValidPlan(): boolean {
      // TODO: Add plan discount validation
      return true;

      // if (this.ticket.idPlano === "") {
      //   return true;
      // }

      // if (!this.$store.state.user.logged) {
      //   return true;
      // }

      // const planos = this.ticket.idPlano.split(",");

      // // Caso o usuário não tenha um cadastro como sócio-torcedor
      // if (this.$store.state.user.data.planos.length === 0) {
      //   return false;
      // }

      // const valid: Plan[] = this.$store.state.user.data.planos.filter(
      //   (plano: Plan) => planos.includes(plano.id.toString())
      // );

      // if (valid.length === 0) {
      //   return false;
      // }

      // return true;
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    quantidade: {
      get(): number {
        return this.value;
      },
      set(quantidade: number) {
        this.$emit("input", quantidade);
      },
    },
  },
  data() {
    return {
      opcoes: {},
      gambi: [
        "b6fc2660-40e5-11ee-a99a-25da020eae4d",
        "b6fbe6f0-40e5-11ee-9bd8-c944767b9308",
        "b6fbf590-40e5-11ee-aae4-1b244568ffd5",
      ],
    };
  },
  watch: {
    soldOut: {
      handler(_new) {
        if (_new === true) {
          this.quantidade = 0;
        }
      },
      immediate: true,
    },
  },
  methods: {
    checkLogin(): boolean {
      if (this.gambi.includes(this.ticket.id) && !this.$store.state.user.logged) {
        this.$dialog.show("/acesso/socio", {
          onClose: () => {
            // Caso o usuário não tenha concluído um login
            if (!this.$store.state.user.logged) {
              this.quantidade = 0;
              return false;
            }
          },
        });
      }

      return true;
    },
    formatCurrency,
  },
});
