import { DynamicStep } from "@/types/DynamicStep";
import Event from "@/types/Event";
import Sector from "@/types/Sector";

// Steps
import ChooseTicketForm from "@/components/checkout/steps/ChooseTicketForm.vue";
import MappedSeatsSelector from "@/components/checkout/steps/MappedSeatsSelector.vue";
import MappedSectionSelector from "@/components/checkout/steps/MappedSectionSelector.vue";
import MappedTicketSelector from "@/components/checkout/steps/MappedTicketSelector.vue";
import PaymentForm from "@/components/checkout/steps/PaymentForm.vue";
import ValidationTicketForm from "@/components/checkout/steps/ValidationTicketForm.vue";
import NominalTicket from "@/types/NominalTicket";

export function steps(event: Event, sectors: Sector[], tickets: Record<string, NominalTicket[]>): DynamicStep[] {
  return [
    {
      component: MappedSectionSelector,
      condition: () => event.mapping !== null,
      props: {
        event: event,
      },
      step: {
        step: 1,
        label: "Escolha de Setor",
        mobileLabel: "Setor",
      },
      ref: "MappedSectionSelector",
      actions: {
        back: () => false,
        next: () => false,
      },
    },
    {
      component: MappedSeatsSelector,
      condition: () => event.mapping !== null,
      props: {
        sector: sectors[0],
        event: event,
      },
      step: {
        step: 2,
        label: "Escolha de Lugares",
        mobileLabel: "Lugares",
      },
      ref: "MappedSeatsSelector",
      actions: {
        back: () => true,
        next: () => true,
      },
    },
    {
      component: MappedTicketSelector,
      condition: () => event.mapping !== null,
      props: {
        sectors: sectors,
        sector: sectors[0],
        event: event,
      },
      step: {
        step: 3,
        label: "Validações e Identificação de Portadores",
        mobileLabel: "Validação e Portadores",
      },
      ref: "MappedTicketSelector",
      actions: {
        back: () => true,
        next: () => true,
      },
    },
    {
      component: ChooseTicketForm,
      condition: () => event.mapping === null,
      value: sectors,
      props: {
        event: event,
      },
      step: {
        step: 1,
        label: "Escolha de Setores e Ingressos",
        mobileLabel: "Setores e Ingressos",
      },
      ref: "ChooseTicketForm",
      actions: {
        back: () => false,
        next: () => true,
      },
    },
    {
      component: ValidationTicketForm,
      condition: () => event.mapping === null,
      value: tickets,
      props: {
        sectors: sectors,
      },
      step: {
        step: 2,
        label: "Validações e Identificação de Portadores",
        mobileLabel: "Validação e Portadores",
      },
      ref: "ValidationTicketForm",
      actions: {
        back: () => true,
        next: () => true,
      },
    },
    {
      component: PaymentForm,
      condition: () => true,
      props: {
        sectors: sectors,
        tickets: tickets,
      },
      step: {
        step: event.mapping !== null ? 4 : 3,
        label: "Resumo e Dados de Pagamento",
        mobileLabel: "Resumo e Pagamento",
      },
      ref: "PaymentForm",
      actions: {
        back: () => true,
        next: () => false,
      },
    },
  ];
}
