import api from "@/api";
import DataResponse from "@/types/DataResponse";

type ChargeBackResponse<T> = DataResponse<T> & {
  is_chargebacked: boolean;
  error: boolean;
};

export default async function checkUserChargeBack(id: string): Promise<boolean> {
  if (id === undefined) return false;

  const response = await api.get<ChargeBackResponse<unknown>>(`api/user/reserva/checkchargeback/${id}`);

  const { is_chargebacked } = response.data;
  return is_chargebacked;
}
