

































































import Vue, { PropType } from "vue";

import CardItem from "./MobileCardItem.vue";
import TBtn from "@/components/core/TBtn.vue";
import TCard from "@/components/core/TCard.vue";

interface NominalTicket {
  name: string;
  cpf: string;
  type: string;
  price: string;
}

export default Vue.extend({
  name: "ShoppingCartCard",

  components: {
    CardItem,
    TBtn,
    TCard,
  },

  props: {
    value: {
      type: Array as PropType<NominalTicket[]>,
      default: () => [],
    },
    sector: {
      type: String,
      default: "",
    },
  },

  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    tickets: {
      get(): NominalTicket[] {
        return this.value;
      },
      set(value: NominalTicket[]) {
        this.$emit("input", value);
      },
    },
  },
});
