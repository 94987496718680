export default class Fee {
  static apply(amount: number, fee: number, mode: "percentual" | "absolute"): number {
    if (mode === "percentual") {
      return (amount * (100 + fee)) / 100;
    }

    return amount + fee;
  }

  static calculate(amount: number, fee: number, mode: "percentual" | "absolute"): number {
    if (mode === "percentual") {
      return (amount * fee) / 100;
    }

    return fee;
  }
}
