import api from "@/api";

interface PaymentConfig {
  pix: null;
  creditcard: {
    id: number;
    extra_fields: {
      public_key: string;
      sandbox: boolean;
      obs: string;
    };
  };
}

async function getPaymentConfig(): Promise<PaymentConfig> {
  const response = await api.get<PaymentConfig>("/api/user/config");
  return response.data;
}

export default getPaymentConfig;
