






import Vue, { PropType } from "vue";
import InlineSvg from "vue-inline-svg";

export default Vue.extend({
  name: "SvgMap",
  components: {
    InlineSvg,
  },
  props: {
    source: {
      type: String,
      required: true,
    },
    selectedSector: {
      type: String as PropType<string | null>,
    },
    locked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sectorSVGElements: [] as Element[],
      viewbox: [] as string[],
      center: { x: 0, y: 0 },
    };
  },
  computed: {
    root(): Vue {
      return this.$refs.root as Vue;
    },
  },
  created() {
    this.updateSelection(this.selectedSector);
  },
  watch: {
    selectedSector: {
      immediate: true,
      handler(newVal) {
        this.updateSelection(newVal);
      },
    },
  },
  methods: {
    setup(): void {
      const gRoot = this.root.$el.querySelector("#root") as SVGGElement;
      this.sectorSVGElements = [...gRoot.querySelectorAll("*[id^='_']")];

      // Builds background root for  the shadow effect on selection
      const background = gRoot.cloneNode(true) as SVGGElement;
      background.id = `background-${background.id}`;
      gRoot.parentElement?.insertBefore(background, gRoot);
      for (const child of gRoot.children) {
        if (this.sectorSVGElements.includes(child)) continue;
        (child as SVGElement).style.opacity = "0";
      }

      const elements = [] as { element: Element; color: string }[];
      for (const sector of this.sectorSVGElements) {
        if (sector.id[0] === "_") {
          sector.id = sector.id.slice(1); // removes _
        }
        const path = this.getPathFromSectorElement(sector) as SVGPathElement;
        if (!path) continue;
        path.style.opacity = "0";
        path.style.transition = "300ms ease";
        elements.push({
          element: sector,
          color: getComputedStyle(path).fill,
        });
        (sector as HTMLElement).style.cursor = "pointer";
        sector.addEventListener("click", this.onClick);
      }
      this.$emit("setup", elements);
      this.updateSelection(this.selectedSector);
    },
    onClick($event: Event) {
      if (this.locked) return;

      const target = $event.target;

      if (target instanceof SVGElement && target.parentElement) {
        this.$emit("click", target.parentElement.id);
      }
    },
    getPathFromSectorElement(sector: Element) {
      return [...sector.children].find((e) => e.tagName === "path");
    },
    updateSelection(sectorId: string | null) {
      this.sectorSVGElements.forEach((e) => {
        const path = this.getPathFromSectorElement(e);

        if (!path) return;

        if (e.id === sectorId) {
          path.classList.toggle("selected");
        } else {
          path.classList.remove("selected");
        }
      });
    },
  },
});
