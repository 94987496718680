


































































































import Vue, { PropType } from "vue";
import TTicketCounter from "@/components/core/TTicketCounter.vue";
import Sector from "@/types/Sector";
import Event from "@/types/Event";
import Row from "@/types/Mapping/Row";
import { SelectionSeat } from "@/types/Mapping/SelectionSeat";
import SeatSelectorCaptions from "@/components/checkout/SeatSelectorCaptions.vue";
import SvgMap from "@/components/Mapping/SvgMap.vue";
import TEventLogo from "@/components/core/Events/TEventLogo.vue";

export default Vue.extend({
  components: {
    TTicketCounter,
    SeatSelectorCaptions,
    TEventLogo,
    SvgMap,
  },

  props: {
    sector: {
      type: Object as PropType<Sector>,
      required: true,
    },
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
  },

  data() {
    return {
      carousel: {
        index: 0,
        container: null as null | HTMLElement,
      },
      statusColors: {
        empty: "#05ae01",
        reserved: "#2681f2",
        occupied: "#e20f24",
        selected: "#f7b500",
        invalid: "#eeeeee",
      } as Record<string, string>,
      sizes: {
        seat: { width: 30, height: 30, marginX: 4 },
        label: { width: 50, height: 30 },
        field: { width: 40, height: undefined },
      },
    };
  },

  mounted(): void {
    this.carousel.container = document.querySelector(".seats-carousel");
  },

  computed: {
    maxTickets() {
      return this.event.quantidade_ingresso_venda_online;
    },
    pages(): number {
      if (this.rows[0].seats === undefined) return 1;

      if (this.rows[0]) return Math.ceil(this.rows[0].seats.length / this.perPage);

      return 1;
    },
    selectedSector(): Sector {
      return this.$store.state.mapping.selected.sector;
    },
    selectedSeats(): SelectionSeat[] {
      return this.$store.state.mapping.selected.seats;
    },
    rows(): Row[] {
      return this.$store.state.mapping.rows;
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    perPage(): number {
      const { seat, label } = this.sizes;

      const safetyMargin = 20;

      if (this.carousel.container === null) return 1;

      const containerWidth = this.carousel.container.offsetWidth;

      return Math.floor((containerWidth - label.width - safetyMargin) / (seat.width + seat.marginX * 2));
    },
  },

  methods: {
    toggleSeat(seat: SelectionSeat) {
      if (seat.status !== "empty" && seat.status !== "selected") return;

      this.$store.commit("mapping/toggleSeat", { seat, limit: this.maxTickets });
    },
    isClickable(seat: SelectionSeat) {
      return (seat.status === "empty" && this.selectedSeats.length < this.maxTickets) || seat.status === "selected";
    },
    validate(): boolean {
      if (this.selectedSeats.length === 0) throw new Error("Você deve escolher ao menos 1 lugar");

      return true;
    },
    async submit(): Promise<boolean> {
      await this.$store.dispatch("mapping/reserveSeats", { event_id: this.event.id });
      return true;
    },
    pageLowerBound(index: number) {
      if (index < this.pages) {
        return (index - 1) * this.perPage;
      }

      if (this.rows[0].seats === undefined) return 0;

      return this.rows[0].seats.length - this.perPage;
    },
  },
});
