










































































import PaymentData from "@/types/PaymentData";
import Vue, { PropType } from "vue";

import TCpfInput from "@/components/core/TCpfInput.vue";
import TDateInput from "@/components/core/TDateInput.vue";
import TCardInput from "@/components/core/TCardInput.vue";
import TEmailInput from "@/components/core/TEmailInput.vue";
import TTextInput from "@/components/core/TTextInput.vue";
import TCreditCard from "@/components/core/TCreditCard.vue";
import getPaymentConfig from "@/api/requests/getPaymentConfig";

export default Vue.extend({
  data() {
    return {
      selectors: {
        numberInput: "input#cc-number",
        expiryInput: "input#cc-expiration",
        cvcInput: "input#cc-cvv",
        nameInput: "input#cc-name",
      },
    };
  },

  props: {
    paymentData: {
      type: Object as PropType<PaymentData>,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    TCpfInput,
    TDateInput,
    TCardInput,
    TEmailInput,
    TTextInput,
    TCreditCard,
  },

  computed: {
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
  },

  methods: {
    async validate() {
      const result = this.form.validate();

      if (!result) return false;

      const config = await getPaymentConfig();

      const expMonth = this.paymentData.card.expiration.split("/")[0];
      const expYear = "20" + this.paymentData.card.expiration.split("/")[1];

      try {
        const pagseguro = window.PagSeguro.encryptCard({
          publicKey: config.creditcard.extra_fields.public_key,
          holder: this.paymentData.card.holder.name,
          number: this.paymentData.card.number.replaceAll(" ", ""),
          expMonth: expMonth,
          expYear: expYear,
          securityCode: this.paymentData.card.cvv,
        });

        if (pagseguro.hasErrors) return false;

        this.paymentData.card.encrypted = pagseguro.encryptedCard;

        return result;
      } catch (error) {
        console.log(error);
      }
    },
  },
});
