



























































import Event from "@/types/Event";
import TicketConfiguration from "@/types/TicketConfiguration";
import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    event: {
      required: true,
      type: Object as PropType<Event>,
    },
  },
  computed: {
    configuration(): TicketConfiguration {
      return this.event.configuration!;
    },
  },
  methods: {
    openMapModal() {
      this.$store.commit("events/setSelectedEvent", this.event);
      this.$dialog.show("/event/map", {
        hasTitle: true,
        hasCloseButton: true,
        persistent: false,
      });
    },
  },
});
