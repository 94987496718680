import api from "@/api";
import PaymentData from "@/types/PaymentData";
import CheckoutResult from "../../types/CheckoutResult";
import { AxiosResponse } from "axios";
import NominalTicket from "@/types/NominalTicket";

type NominalTickets = Record<string, NominalTicket[]>;

export default async function checkout(
  reservaId: string,
  paymentData: PaymentData,
  tickets: NominalTickets
): Promise<CheckoutResult> {
  const data: PaymentData = JSON.parse(JSON.stringify(paymentData));

  data.card.bin = data.card.number.replaceAll(" ", "").substring(0, 6);

  if (data.card && data.card.encrypted) {
    data.card.number = "";
    data.card.expiration = "";
  }

  const response: AxiosResponse<CheckoutResult> = await api.post(`/api/user/reserva/${reservaId}/checkout`, {
    ...data,
    tickets,
  });

  return response.data;
}
