













































































import Vue, { PropType } from "vue";
import formatCurrency from "@/filters/currency";
import Event from "@/types/Event";
import Sector from "@/types/Sector";
import SvgMap from "@/components/Mapping/SvgMap.vue";
import TBtn from "@/components/core/TBtn.vue";
import TicketConfiguration from "@/types/TicketConfiguration";

export default Vue.extend({
  components: {
    SvgMap,
    TBtn,
  },
  props: {
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
  },
  created() {
    // if (!this.$store.getters["mapping/isTimerActive"]) {
    //   this.$store.commit("mapping/clearReservation");
    // }
  },
  data() {
    return {
      mapping: {
        colors: {} as Record<string, string>,
        selectedPanel: undefined as undefined | number,
        highlightedSectorId: null as null | string,
      },
    };
  },
  computed: {
    loading(): boolean {
      return this.$loader.loading();
    },
    sectors(): Sector[] {
      return this.$store.state.cart.sectors;
    },
    selectedSector(): Sector {
      return this.$store.state.mapping.selected.sector;
    },
    step(): number {
      return this.$store.state.mapping.stepper.step;
    },
    config(): TicketConfiguration {
      return this.event.configuration!;
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    "mapping.selectedPanel": function (_new) {
      if (_new === undefined) {
        this.mapping.highlightedSectorId = null;
        return;
      }

      this.mapping.highlightedSectorId = this.sectors[_new].id;
    },
  },
  methods: {
    parse(payload: { element: Element; color: string }[]) {
      payload.forEach((item) => {
        const sector = this.sectors.find((s) => item.element.id.endsWith(s.id));

        if (!sector) return;

        this.mapping.colors[item.element.id] = item.color;
      });

      this.$forceUpdate();
    },
    expand(sectorId: string): void {
      if (sectorId === this.mapping.highlightedSectorId) {
        this.mapping.selectedPanel = undefined;
        this.mapping.highlightedSectorId = null;
        return;
      }

      const sector = this.sectors.find((s) => s.id === sectorId);

      if (sector) {
        const index = this.sectors.indexOf(sector);
        this.mapping.selectedPanel = index;
        this.mapping.highlightedSectorId = sectorId;
        this.scroll();
      }
    },
    select(sectorId: string) {
      const sector = this.sectors.find((s) => s.id === sectorId);

      if (this.$store.state.user.logged) {
        this.$store.commit("mapping/setSector", sector);
        this.$store.dispatch("mapping/fetchRows", { event: this.event });
        this.$emit("next");
        return;
      }

      this.$dialog.show("/login", {
        onClose: () => {
          if (!this.$store.state.user.logged) return;
          this.$store.commit("mapping/setSector", sector);
          this.$store.dispatch("mapping/fetchRows", { event: this.event });
          this.$emit("next");
        },
      });
    },
    scroll(): void {
      if (!this.isMobile) return;

      let ref = this.$refs[`panel-${this.mapping.highlightedSectorId}`];

      if (ref instanceof Array) ref = ref[0];

      if (ref === undefined) return;

      (ref as Vue).$el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    },
    formatCurrency,
  },
});
