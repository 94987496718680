












import Vue, { PropType } from "vue";
import Event from "@/types/Event";

export default Vue.extend({
  name: "EventDescription",

  props: {
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
  },

  computed: {
    loading(): boolean {
      return this.$loader.loading();
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    openDescriptionModal() {
      this.$store.commit("events/setSelectedEvent", this.event);
      this.$dialog.show("/event/description", {
        hasTitle: true,
        hasCloseButton: true,
        persistent: false,
      });
    },
  },
});
