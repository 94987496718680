
















































import Vue from "vue";

// Requests
import getEventById from "@/api/requests/getEventById";
import getTicketsBySector from "@/api/requests/getTicketsBySector";

// UI
import TimerReservationTicket from "@/components/Ticket/TimerReservationTicket.vue";
import CartSummaryBar from "@/components/Ticket/CartSummaryBar.vue";
import CheckoutFooterActions from "@/components/checkout/CheckoutFooterActions.vue";
import EventDisplay from "@/components/checkout/event/event-display.vue";
import ShoppingCart from "@/components/Ticket/ShoppingCart.vue";
import TBtn from "@/components/core/TBtn.vue";
import TSection from "@/components/core/TSection.vue";
import TStepper, { Step } from "@/components/core/TStepper.vue";

// Types
import { DateTime } from "luxon";
import { DynamicStep, DynamicStepComponent } from "@/types/DynamicStep";
import axios, { AxiosError } from "axios";
import ErrorResponse from "@/types/ErrorResponse";
import Event from "@/types/Event";
import NominalTicket from "@/types/NominalTicket";
import Sector from "@/types/Sector";
import { steps as generateSteps } from "@/utils/checkout";

export default Vue.extend({
  name: "PaymentCheckout",

  components: {
    CartSummaryBar,
    CheckoutFooterActions,
    EventDisplay,
    ShoppingCart,
    TBtn,
    TimerReservationTicket,
    TSection,
    TStepper,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  beforeMount() {
    this.getEvent();
    this.getLots();
  },

  data: () => ({
    event: {} as Event,
  }),

  computed: {
    loading(): boolean {
      return this.$loader.loading();
    },
    steps(): DynamicStep[] {
      return generateSteps(this.event, this.sectors, this.tickets);
    },
    filteredSteps(): DynamicStep[] {
      return this.steps.filter((s) => s.condition());
    },
    currentStep(): DynamicStep {
      return this.filteredSteps[this.step - 1];
    },
    vuetifySteps(): Step[] {
      return this.filteredSteps.map((s) => s.step);
    },
    step(): number {
      return this.$store.state.cart.control.step;
    },
    sectors(): Sector[] {
      return this.$store.state.cart.sectors;
    },
    tickets(): Record<string, NominalTicket[]> {
      return this.$store.state.cart.tickets;
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
  },

  methods: {
    scrollToElement(): void {
      const offset = (document.querySelector(".v-stepper__items") as HTMLElement)?.offsetTop ?? 0;
      setTimeout(() => {
        window.scrollTo({ top: 0, left: offset, behavior: "smooth" });
      }, 100);
    },
    async getEvent(): Promise<void> {
      this.$loader.increment();

      try {
        this.event = await getEventById(this.id);

        const now = DateTime.now();

        const start = DateTime.fromFormat(this.event.inicio_venda_online, "yyyy-MM-dd HH:mm:ss");

        const end = DateTime.fromFormat(this.event.termino_venda_online, "yyyy-MM-dd HH:mm:ss");

        this.$store.commit("events/setSelectedEvent", this.event);
        this.$store.dispatch("producers/analytics", {
          producer_id: this.event.cliente_id,
          action: "PageView",
        });

        if (!(now > start && now < end)) {
          this.steps = [];
        }
      } catch (error) {
        this.$snackbar(error, "error");
      } finally {
        this.$loader.decrement();
      }
    },
    async getLots(): Promise<void> {
      this.$loader.increment();

      try {
        const sectors = (await getTicketsBySector(this.id)).data;
        sectors.sort((a, b) => a.order - b.order);

        for (const sector of sectors) {
          for (const section of sector.sections) {
            section.tipos_ingresso = section.tipos_ingresso.sort((a, b) => parseFloat(b.preco) - parseFloat(a.preco));
          }
        }

        this.$store.commit("cart/setSectors", sectors);
      } catch (error) {
        console.log(error);
        this.$snackbar(error, "error");
      } finally {
        this.$loader.decrement();
      }
    },
    async cancel(): Promise<void> {
      this.$loader.increment("button");

      const candidates = this.$refs[this.currentStep.ref] as DynamicStepComponent[];

      try {
        if (candidates.length === 0) throw new Error("Componente não encontrado");

        const component = candidates[0];

        const canceled = component.cancel === undefined || (await component.cancel());

        if (canceled) {
          this.$store.commit("cart/setStep", Math.max(this.step - 1, 1));
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const httpError = error as AxiosError<ErrorResponse>;
          this.$snackbar(httpError.response?.data.message, "error");
        } else if (error instanceof Error && error.message !== undefined) {
          this.$snackbar(error.message, "error");
        } else {
          this.$snackbar("Não foi possível concluir seu pedido!", "error");
        }
      }

      this.$loader.decrement("button");
    },
    async validate(): Promise<void> {
      this.$loader.increment("button");

      try {
        const candidates = this.$refs[this.currentStep.ref] as DynamicStepComponent[];

        if (candidates.length === 0) throw new Error("Componente não encontrado");

        const component = candidates[0];

        const valid = component.validate === undefined || (await component.validate());

        if (!valid) {
          this.$loader.decrement("button");
          return;
        }

        const submitted = component.submit === undefined || (await component.submit());

        if (!submitted) {
          this.$loader.decrement("button");
          return;
        }

        // If it's the last step, don't skip
        this.$store.commit("cart/setStep", this.step + 1);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const httpError = error as AxiosError<ErrorResponse>;
          this.$snackbar(httpError.response?.data.message, "error");
        } else if (error instanceof Error && error.message !== undefined) {
          this.$snackbar(error.message, "error");
        } else {
          this.$snackbar("Não foi possível concluir seu pedido!", "error");
        }
      } finally {
        this.$loader.decrement("button");
      }

      this.scrollToElement();
    },
  },
});
