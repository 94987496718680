interface FormatCurrencyOptions {
  currency: string;
}

export default function formatCurrency(value: number, options?: FormatCurrencyOptions): string {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: options?.currency || "BRL",
  }).format(value);
}
