






































































































































































import Vue, { PropType, VueConstructor } from "vue";

import checkout from "@/api/requests/checkout";

import TCard from "@/components/core/TCard.vue";
import TAlert from "@/components/core/TAlert.vue";
import Sector from "@/types/Sector";
import PaymentData from "@/types/PaymentData";
import CheckoutResult from "@/types/CheckoutResult";
import NominalTicket from "@/types/NominalTicket";
import CreditCardForm from "@/components/Ticket/PaymentForms/CreditCardForm.vue";
import PixForm from "@/components/Ticket/PaymentForms/PixForm.vue";
import Order from "@/types/Order";
import PixPayment from "@/types/PixPayment";
import Section from "@/types/Section";
import checkUserChargeBack from "@/api/requests/checkUserChargeBack";
import getOrderDetails from "@/api/requests/getOrderDetails";
import Event from "@/types/Event";
import formatCurrency from "@/utils/formatCurrency";
import Fee from "@/utils/fees";

type Form = Vue & { validate: () => boolean };

interface Method {
  id: number;
  name: string;
  key: "credit-card" | "pix";
  icon: string;
  component: VueConstructor;
  condition(): boolean;
}

export default Vue.extend({
  name: "PaymentForm",
  created() {
    this.mountPaymentMethods();
  },
  components: {
    CreditCardForm,
    PixForm,
    TCard,
    TAlert,
  },
  data() {
    return {
      methods: [] as Method[],
      selected: 0,
      loading: false,
      order: {} as Order,
      paymentData: {
        card: {
          number: "",
          cvv: "",
          name: "",
          expiration: "",
          holder: {
            document: "",
            name: "",
            email: "",
            birth_date: "",
          },
        },
        installments: 1,
        method: "credit-card",
      } as PaymentData,
      timeout: 0 as ReturnType<typeof setTimeout> | undefined,
    };
  },

  props: {
    sectors: {
      type: Array as PropType<Sector[]>,
    },
    tickets: {
      type: Object as PropType<Record<string, NominalTicket[]>>,
      required: true,
    },
  },

  computed: {
    event(): Event {
      return this.$store.state.events.selected;
    },
    payment(): PixPayment {
      return this.$store.state.cart.payment;
    },
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
    token(): string {
      return this.$store.state.user.token.access_token;
    },
    subtotal(): number {
      let total = 0;

      for (const sector of this.sectors) {
        for (const section of sector.sections) {
          total += section.tipos_ingresso.reduce((acc, t) => acc + t.quantidade * parseFloat(t.preco), 0);
        }
      }

      return total;
    },
    conveniencia(): number {
      let total = 0;

      for (const sector of this.sectors) {
        for (const section of sector.sections) {
          for (const type of section.tipos_ingresso) {
            const fee = Fee.calculate(
              parseFloat(type.preco),
              this.event.convenience_fee,
              this.event.convenience_fee_mode
            );

            total += fee * type.quantidade;
          }
        }
      }

      return total;
    },
    filteredSectors(): Sector[] {
      return this.sectors.filter((sector) => {
        return sector.sections.some((section) => {
          return section.tipos_ingresso.reduce((acc, t) => acc + t.quantidade, 0) > 0;
        });
      });
    },
    filteredMethods(): Method[] {
      return this.methods.filter((m) => m.condition());
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
    hasCardBlock(): boolean {
      for (const sector of this.filteredSectors) {
        for (const section of sector.sections) {
          for (const type of this.filteredTypes(section)) {
            if (type.card_blocks !== undefined && type.card_blocks.length > 0) {
              return true;
            }
          }
        }
      }

      return false;
    },
  },

  methods: {
    redirectExpired() {
      this.$store.commit("cart/reset");
      this.$router.push({
        path: "/ticket/payments",
        query: { status: "Recusado" },
      });
    },
    getTicketValuesBySector(sector: Sector) {
      const sum = sector.sections.reduce(
        (acc, section) =>
          acc + section.tipos_ingresso.reduce((accType, type) => accType + parseFloat(type.preco) * type.quantidade, 0),
        0
      );
      return sum;
    },
    handleCartTotal() {
      return this.filteredSectors.reduce((acc, sector) => this.getTicketValuesBySector(sector), 0) * 1.1;
    },
    filteredTypes(section: Section) {
      return section.tipos_ingresso.filter((t) => t.quantidade > 0);
    },
    async mountPaymentMethods() {
      const chargedBack = await this.userHasChargeback();

      const hasPix = this.event.payment_methods.find((pm) => pm.key === "PIX") !== undefined && !this.hasCardBlock;

      const hasCc = this.event.payment_methods.find((pm) => pm.key === "CreditCard") !== undefined;

      if (hasPix) {
        this.methods.push({
          id: 1,
          name: "PIX",
          icon: "",
          key: "pix",
          component: PixForm,
          condition: () => !this.hasCardBlock,
        });
      }

      if (hasCc) {
        this.methods.push({
          id: 2,
          name: "Cartão de Crédito",
          icon: "mdi mdi-credit-card",
          key: "credit-card",
          component: CreditCardForm,
          condition: () => this.payment === null && !chargedBack,
        });
      }
    },
    cancel(): boolean {
      if (this.event.mapping !== null) {
        this.$store.commit("cart/resetTimer");
      }

      return true;
    },
    async validate(): Promise<boolean> {
      if (this.$refs[this.methods[this.selected].key] === undefined) {
        throw new Error("Método de pagamento inválido!");
      }

      const candidates = this.$refs[this.methods[this.selected].key] as (Vue | Element)[];
      const form = candidates[0] as Form;

      if (!(await form.validate())) {
        throw new Error("Erro de validação do formulário");
      }

      const reservation = JSON.parse(localStorage.getItem("reservation") as string);

      if (reservation === null) {
        this.$router.back();
        throw new Error("Sua reserva expirou!");
      }

      return true;
    },
    async pay(): Promise<CheckoutResult> {
      const reservation = JSON.parse(localStorage.getItem("reservation") as string);

      this.paymentData.method = this.methods[this.selected].key;

      if (!Array.isArray(this.$route.query.rel) && this.$route.query.rel !== undefined) {
        this.paymentData.affiliateId = this.$route.query.rel;
      }

      return await checkout(reservation.id, this.paymentData, this.$store.state.cart.tickets);
    },
    async userHasChargeback() {
      try {
        const { id: user_id } = this.$store.state.user.data;
        const is_chargebacked = await checkUserChargeBack(user_id);
        return is_chargebacked;
      } catch (e) {
        console.log(e);
      }
      return true;
    },
    async submit() {
      this.$store.commit("cart/resetTimer");

      const result = await this.pay();

      this.order = result.data.order;

      switch (this.paymentData.method) {
        case "credit-card":
          this.redirect();
          break;
        case "pix":
          this.$store.commit("cart/setPayment", result.data.payment as PixPayment);
          setTimeout(this.checkOrderStatus, 5000);
          break;
        default:
          break;
      }
    },
    async redirect(): Promise<void> {
      this.$snackbar("Pagamento concluído com sucesso!", "success");

      this.$store.commit("ticket/setSelectedOrder", this.order);

      this.$store.commit("cart/reset");

      this.$store.dispatch("producers/analytics", {
        producer_id: this.event.cliente_id,
        action: "Purchase",
        params: { currency: "BRL", value: this.order.valor_total },
      });

      this.$router.push(`/ticket/list/${this.order.id}`);
    },
    async checkOrderStatus() {
      try {
        const order = await getOrderDetails(this.order.id);
        if (order.situacao === "Confirmado") {
          this.order = order;
          this.redirect();
        } else {
          setTimeout(this.checkOrderStatus, 5000);
        }
      } catch (error) {
        console.log(error);
        this.$snackbar(error, "error");
      }
    },
    formatCurrency,
    calculateFee: Fee.calculate,
    applyFee: Fee.apply,
  },
});
