






























































import Vue, { PropType } from "vue";

import TAlert from "@/components/core/TAlert.vue";
import ValidationTicketCard from "@/components/Ticket/ValidationTicketCard.vue";
import Sector from "@/types/Sector";
import Event from "@/types/Event";
import Fee from "@/utils/fees";

interface NominalTicket {
  type: string;
  name: string;
  cpf: string;
}

type NominalTickets = Record<string, NominalTicket[]>;

type Form = Vue & { validate: () => boolean };

export default Vue.extend({
  name: "ValidationTicketForm",

  components: {
    ValidationTicketCard,
    TAlert,
  },

  props: {
    value: {
      type: Object as PropType<NominalTickets>,
      default: () => ({}),
    },
    sectors: {
      type: Array as PropType<Sector[]>,
      required: true,
    },
  },

  computed: {
    event(): Event {
      return this.$store.state.events.selected;
    },
    tickets: {
      get(): NominalTickets {
        return this.value;
      },
      set(value: NominalTickets) {
        this.$emit("input", value);
      },
    },
    filteredSectors(): Sector[] {
      return this.sectors.filter((s) => Object.keys(this.value).includes(s.id));
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    getValorIngressos(): number {
      let total = 0;

      for (const sector of this.sectors) {
        for (const section of sector.sections) {
          total += section.tipos_ingresso.reduce((acc, type) => acc + parseFloat(type.preco) * type.quantidade, 0);
        }
      }

      return total;
    },
    getQuantidadeIngressos(): number {
      let total = 0;

      for (const sector of this.sectors) {
        for (const section of sector.sections) {
          total += section.tipos_ingresso.reduce((acc, type) => acc + type.quantidade, 0);
        }
      }

      return total;
    },
    getTaxaConveniencia(): number {
      let total = 0;

      for (const sector of this.sectors) {
        for (const section of sector.sections) {
          for (const type of section.tipos_ingresso) {
            const fee = Fee.calculate(
              parseFloat(type.preco),
              this.event.convenience_fee,
              this.event.convenience_fee_mode
            );

            total += fee * type.quantidade;
          }
        }
      }

      return total;
    },
  },

  methods: {
    validate(): boolean {
      const validations: boolean[] = [];

      Object.keys(this.$refs).forEach((el) => {
        const arr = this.$refs[el] as Vue[];
        const current = arr[0] as Form;
        validations.push(current?.validate());
      });

      return validations.every((a) => a === true || a === undefined);
    },
    cancel(): boolean {
      this.$store.commit("cart/resetTimer");
      return true;
    },
  },
  mounted() {
    this.validate();
  },
});
