






























































import Vue, { PropType } from "vue";

import ChooseTicketCardItem from "./ChooseTicketCardItem.vue";
import TCard from "@/components/core/TCard.vue";
import Section from "@/types/Section";

import Event from "@/types/Event";

export default Vue.extend({
  name: "ChooseTicket",
  components: {
    TCard,
    ChooseTicketCardItem,
  },

  props: {
    value: {
      type: Array as PropType<Section[]>,
      default: () => [],
    },
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
    globalSelectedTickets: {
      type: Number,
      required: true,
    },
  },

  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    sections: {
      get(): Section[] {
        return this.value;
      },
      set(value: Section[]) {
        this.$emit("input", value);
      },
    },
    subtotal(): string {
      let result = 0;

      this.sections.forEach((section) => {
        section.tipos_ingresso.forEach((type) => {
          result += parseFloat(type.preco) * type.quantidade;
        });
      });

      return this.$options.filters?.currency(result);
    },
  },
});
