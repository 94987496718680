import api from "@/api";

import { AxiosResponse } from "axios";

import Sector from "@/types/Sector";

export default async function getTicketsBySector(evento_id: string | number): Promise<AxiosResponse<Sector[]>> {
  const response = await api.get<Sector[]>(`/api/events/${evento_id}/tickets`);

  return {
    ...response,
    data: response.data,
  };
}
