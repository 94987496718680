

































import NominalTicket from "@/types/NominalTicket";
import Sector from "@/types/Sector";
import Vue from "vue";
import TBtn from "@/components/core/TBtn.vue";
import Event from "@/types/Event";
import Fee from "@/utils/fees";

type NominalTickets = Record<string, NominalTicket[]>;

export default Vue.extend({
  components: {
    TBtn,
  },
  props: {
    loading: {
      required: true,
      type: Boolean,
    },
  },
  name: "CartSummaryBar",
  computed: {
    event(): Event {
      return this.$store.state.events.selected;
    },
    step(): number {
      return this.$store.state.cart.control.step;
    },
    sectors(): Sector[] {
      return this.$store.state.cart.sectors;
    },
    tickets(): NominalTickets {
      return this.$store.state.cart.tickets;
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
    getValorIngressos(): number {
      let total = 0;

      for (const sector of this.sectors) {
        for (const section of sector.sections) {
          total += section.tipos_ingresso.reduce((acc, type) => acc + parseFloat(type.preco) * type.quantidade, 0);
        }
      }

      return total;
    },
    getQuantidadeIngressos(): number {
      let total = 0;

      for (const sector of this.sectors) {
        for (const section of sector.sections) {
          total += section.tipos_ingresso.reduce((acc, type) => acc + type.quantidade, 0);
        }
      }

      return total;
    },
    getTaxaConveniencia(): number {
      let total = 0;

      for (const sector of this.sectors) {
        for (const section of sector.sections) {
          for (const type of section.tipos_ingresso) {
            const fee = Fee.calculate(
              parseFloat(type.preco),
              this.event.convenience_fee,
              this.event.convenience_fee_mode
            );

            total += fee * type.quantidade;
          }
        }
      }

      return total;
    },
  },
});
