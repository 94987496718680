

















import Vue from "vue";

export default Vue.extend({
  name: "ChoosenTicketCardItem",

  props: {
    bold: {
      type: Boolean,
      default: false,
    },
    noDivider: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: undefined,
    },
  },
});
