















































































































































import Vue, { PropType } from "vue";
import TCard from "@/components/core/TCard.vue";
import TSelect from "@/components/core/TSelect.vue";
import TTextInput from "@/components/core/TTextInput.vue";
import TCpfInput from "@/components/core/TCpfInput.vue";
import CardItem from "@/components/Ticket/MobileCardItem.vue";
import Section from "@/types/Section";
import Event from "@/types/Event";
import SeatWithTicketType from "@/types/Mapping/SeatWithTicketType";
import Sector from "@/types/Sector";
import { SelectionSeat } from "@/types/Mapping/SelectionSeat";
import TicketType from "@/types/TicketType";
import Fee from "@/utils/fees";

export default Vue.extend({
  components: {
    TCard,
    TSelect,
    TTextInput,
    TCpfInput,
    CardItem,
  },

  props: {
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
  },

  data() {
    return {
      ticketTypes: [] as TicketType[],
      loading: false,
    };
  },

  computed: {
    tickets: {
      get(): SeatWithTicketType[] {
        return this.$store.state.mapping.selected.tickets;
      },
      set(value: SeatWithTicketType[]) {
        console.log(value);
      },
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    sector(): Sector {
      return this.$store.state.mapping.selected.sector;
    },
    seats(): SelectionSeat[] {
      return this.$store.state.mapping.selected.seats;
    },
    section(): Section | null {
      return this.$store.getters["mapping/section"];
    },
    types(): TicketType[] | null {
      if (this.section === null) return null;

      return this.$store.getters["mapping/types"];
    },
    getTicketAmount(): number {
      let total = 0;

      for (const section of this.sector.sections) {
        total += section.tipos_ingresso.reduce((acc, type) => acc + type.quantidade, 0);
      }

      return total;
    },
  },

  methods: {
    async submit(): Promise<boolean> {
      await this.$recaptchaLoaded();

      const token = await this.$recaptcha("submit");

      await this.$store.dispatch("cart/createReservation", { event_id: this.event.id, token, seats: this.tickets });

      this.$store.commit("cart/startTimer");

      return true;
    },
    cancel(): boolean {
      this.$store.commit("cart/resetTimer");

      return true;
    },
    changeType(ticket: SeatWithTicketType, type: TicketType): void {
      this.$store.dispatch("mapping/changeTypeFromSeat", { ticket, type });
    },
    validate() {
      return (this.$refs.form as Vue & { validate: () => boolean }).validate();
    },
    getValorIngressos(): number {
      let total = 0;

      for (const section of this.sector.sections) {
        total += section.tipos_ingresso.reduce((acc, type) => acc + parseFloat(type.preco) * type.quantidade, 0);
      }

      return total;
    },
    getTaxaConveniencia(): number {
      let total = 0;

      for (const section of this.sector.sections) {
        for (const type of section.tipos_ingresso) {
          const fee = Fee.calculate(
            parseFloat(type.preco),
            this.event.convenience_fee,
            this.event.convenience_fee_mode
          );

          total += fee * type.quantidade;
        }
      }

      return total;
    },
    formatCurrency(value: number): string {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
  },
});
