






























import Vue, { PropType } from "vue";
import Event from "@/types/Event";

export default Vue.extend({
  name: "EventRules",

  props: {
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
  },

  computed: {
    loading(): boolean {
      return this.$loader.loading();
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
});
