





















































import { DynamicStep } from "@/types/DynamicStep";
import Vue, { PropType } from "vue";
import TBtn from "@/components/core/TBtn.vue";
import TimerReservationTicket from "@/components/Ticket/TimerReservationTicket.vue";

export default Vue.extend({
  components: {
    TBtn,
    TimerReservationTicket,
  },
  props: {
    currentStep: {
      required: true,
      type: Object as PropType<DynamicStep>,
    },
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
    step(): number {
      return this.$store.state.cart.control.step;
    },
  },
});
