












import Vue from "vue";
import { DateTime } from "luxon";

type TimerStore = {
  isRunning: boolean;
  time?: string;
};

export default Vue.extend({
  name: "TimerReservationTicket",
  components: {},
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    minutes: 0 as number,
    seconds: 0 as number,
    interval: undefined as number | undefined,
  }),
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
    timer(): TimerStore {
      return this.$store.state.cart.timer;
    },
    showTimer() {
      return `${this.minutes.toString().padStart(2, "0")}:${this.seconds.toString().padStart(2, "0")}`;
    },
  },
  mounted() {
    this.getTimer();
  },
  methods: {
    getTimer() {
      if (this.timer.isRunning) {
        const storeDate = DateTime.fromISO(this.timer.time!);
        const expiresIn = storeDate.plus({ minutes: 15 });

        this.countdown(expiresIn);

        if (this.interval !== undefined) clearInterval(this.interval);

        this.interval = setInterval(() => this.countdown(expiresIn), 1000);
      }
    },
    countdown(expiresIn: DateTime) {
      const diff = expiresIn.diffNow(["minutes", "seconds"]).toObject();
      this.updateTimer(diff.minutes!, diff.seconds!);
    },
    updateTimer(minutes: number, seconds: number) {
      this.minutes = minutes;
      this.seconds = Math.floor(seconds);
    },
  },
  watch: {
    timer() {
      this.getTimer();
    },
    seconds() {
      if (this.minutes <= 0 && this.seconds <= 0) {
        clearInterval(this.interval);
        this.$store.commit("cart/clearCart");
        this.$store.commit("cart/setStep", 1);
      }
    },
  },
});
