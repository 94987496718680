var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"mr-2",attrs:{"cols":"auto"}},[_vm._v(" Confira e confirme os dados do(s) portador(es) ")]),_c('v-col',[_c('v-divider',{staticClass:"primary"})],1)],1),_c('v-row',{staticClass:"flex-column"},_vm._l((_vm.filteredSectors),function(sector){return _c('v-col',{key:sector.id},[_c('validation-ticket-card',{ref:("form-" + (sector.id)),refInFor:true,attrs:{"sector":sector},model:{value:(_vm.tickets[sector.id]),callback:function ($$v) {_vm.$set(_vm.tickets, sector.id, $$v)},expression:"tickets[sector.id]"}})],1)}),1),(_vm.getQuantidadeIngressos > 1 && !_vm.isMobile)?_c('t-alert',{staticClass:"my-3",attrs:{"type":"warning"}},[_vm._v(" Você poderá alterar os dados dos portadores após a compra ser finalizada. ")]):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"mx-md-0 mx-2"},[_c('v-sheet',{attrs:{"color":"grey lighten-2 pa-4 rounded grey--text text--darken-2"}},[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"d-flex justify-start ps-0 align-start",staticStyle:{"max-width":"40px"},attrs:{"cols":"2","md":"1"}},[_c('v-icon',{attrs:{"color":"grey darken-2","size":_vm.isMobile ? 32 : 40}},[_vm._v("mdi-cart-outline")])],1),_c('v-col',{attrs:{"cols":"10","md":"9"}},[_c('h3',{staticClass:"text-uppercase",style:({
                'font-size': _vm.isMobile ? '0.8em' : '1.05em',
                'font-weight': 'normal',
              })},[_vm._v(" "+_vm._s(_vm.getQuantidadeIngressos)+" "+_vm._s(_vm.getQuantidadeIngressos > 1 ? "Ingressos" : "Ingresso")+" no carrinho ")]),_c('h4',{style:({
                'font-size': _vm.isMobile ? '1.05em' : '1.3em',
                'font-weight': 'normal',
              })},[_vm._v(" R$ "+_vm._s(_vm.getValorIngressos.toFixed(2).replace(".", ","))+" ")]),_c('small',{style:({
                'font-size': _vm.isMobile ? '0.7em' : '0.85em',
                'font-weight': 'normal',
              })},[_vm._v(" TAXA DE CONVENIÊNCIA: R$ "+_vm._s(_vm.getTaxaConveniencia.toFixed(2).replace(".", ",")))])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }