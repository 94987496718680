

















import Vue, { PropType } from "vue";

import ShoppingCartCard from "./ShoppingCartCard.vue";

interface NominalTicket {
  type: string;
  name: string;
  cpf: string;
  price: string;
}

type NominalTickets = Record<string, NominalTicket[]>;

export default Vue.extend({
  name: "ShoppingChart",

  components: {
    ShoppingCartCard,
  },

  props: {
    paymentId: {
      type: String,
      default: "",
    },
    value: {
      type: Object as PropType<NominalTickets>,
      default: () => ({}),
    },
  },

  computed: {
    sectors: {
      get(): NominalTickets {
        return this.value;
      },
      set(value: NominalTickets) {
        this.$emit("input", value);
      },
    },
  },
});
