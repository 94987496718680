<template>
  <v-row>
    <v-col cols="12">
      <div class="credit-card"></div>
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import Card from "card";

export default Vue.extend({
  data() {
    return {
      card: undefined,
    };
  },
  props: {
    form: {
      type: String,
    },
    formSelectors: {
      type: Object,
    },
  },
  mounted() {
    this.card = new Card({
      form: this.form,
      formSelectors: this.formSelectors,
      container: "div.credit-card",
      formatting: true,
      width: 350,
      placeholders: {
        number: "•••• •••• •••• ••••",
        name: "Nome Completo",
        expiry: "••/••",
        cvc: "•••",
      },
    });
  },
});
</script>
