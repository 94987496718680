import api from "@/api";
// import axios from "axios";

import Event from "@/types/Event";
import DataResponse from "@/types/DataResponse";

export default async function getEventById(id: string | number): Promise<Event> {
  const response = await api.get<DataResponse<Event>>(`/api/events/${id}`);

  return response.data.data;
}
