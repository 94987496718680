

















































import Vue, { PropType } from "vue";
import Event from "@/types/Event";
import TEventLogo from "@/components/core/Events/TEventLogo.vue";
import { DateTime } from "luxon";
import EventDescription from "./event-description.vue";
import EventInfo from "./event-info.vue";
import EventRules from "./event-rules.vue";

export default Vue.extend({
  name: "EventDisplay",

  data() {
    return {
      luxon: DateTime,
    };
  },

  components: {
    TEventLogo,
    EventDescription,
    EventInfo,
    EventRules,
  },

  props: {
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
  },

  computed: {
    loading(): boolean {
      return this.$loader.loading();
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    share(): void {
      if (this.isMobile) {
        window.navigator.share({
          title: this.event.nome,
          url: `${window.location.protocol}//${window.location.hostname}/ticket/buy/${this.event.id}`,
        });
      } else {
        navigator.clipboard.writeText(`${window.location.origin}/ticket/buy/${this.event.id}`);
      }
    },
    openDescriptionModal() {
      this.$store.commit("events/setSelectedEvent", this.event);
      this.$dialog.show("/event/description", {
        hasTitle: true,
        hasCloseButton: true,
        persistent: false,
      });
    },
  },
});
