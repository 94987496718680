

































import Vue from "vue";

export default Vue.extend({
  name: "TNumberInput",

  props: {
    appendIcon: {
      type: String,
      default: "mdi-plus",
    },
    max: {
      type: [Number, String],
      default: undefined,
    },
    min: {
      type: [Number, String],
      default: "0",
    },
    prependInnerIcon: {
      type: String,
      default: "mdi-minus",
    },
    unit: {
      type: [Number, String],
      default: 1,
    },
    value: {
      type: [Number, String],
      default: 0,
    },
  },

  computed: {
    model: {
      get(): number | string {
        return this.getSafeValue(this.value);
      },
      set(value: number) {
        this.$emit("input", this.getSafeValue(value));
      },
    },
  },

  methods: {
    add() {
      const unit = Number(this.unit);
      const model = Number(this.model);

      if (!this.hasMaxValue()) {
        this.model = model + unit;
        return;
      }

      const max = Number(this.max);

      if (unit + model > max) return;

      this.model = model + unit;
    },
    hasMaxValue(): boolean {
      return this.max !== null && this.max !== undefined;
    },
    hasMinValue(): boolean {
      return this.min !== null && this.min !== undefined;
    },
    subtract() {
      const unit = Number(this.unit);
      const model = Number(this.model);

      if (!this.hasMinValue()) {
        this.model = model + unit;
        return;
      }

      const min = Number(this.min);

      if (model - unit < min) return;

      this.model = model - unit;
    },
    getSafeValue(value: number | string) {
      const parsedValue = Number(value);

      if (Number.isNaN(parsedValue)) return 0;

      if (this.hasMaxValue() && parsedValue > Number(this.max)) return Number(this.max);

      if (this.hasMinValue() && parsedValue < Number(this.min)) return Number(this.min);

      return value;
    },
  },
});
