




import Vue, { PropType } from "vue";

import TTextInput from "@/components/core/TTextInput.vue";

export default Vue.extend({
  name: "TCardInput",

  props: {
    format: {
      type: String,
      default: "XXXX XXXX XXXX XXXX",
    },
    rules: {
      type: Array as PropType<Array<(v?: string) => boolean | string> | []>,
      default: () => [],
    },
    value: {
      type: String,
      default: undefined,
    },
  },

  components: {
    TTextInput,
  },

  computed: {
    validationRules(): Array<(cardNumber?: string) => boolean | string> | [] {
      const validateCardNumberFormat = (cardNumber?: string): boolean | string => {
        if (!cardNumber) return true;

        const regex = /^([0-9]{4}) [0-9]{4} [0-9]{4} ([0-9]{4})$/g;

        return regex.test(cardNumber) || `O número do cartão precisa estar no formato ${this.format}`;
      };

      const validateCardNumber = (cardNumber?: string): boolean | string => {
        if (!cardNumber) return true;

        const parsedCardNumber = cardNumber.length != 19;
        return !parsedCardNumber || "Número do cartão inválido";
      };

      return [...this.rules, validateCardNumberFormat, validateCardNumber];
    },
  },
});
