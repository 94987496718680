









import Vue from "vue";

type SeatCaptionItem = {
  title: string;
  class: string;
};

export default Vue.extend({
  data() {
    return {
      items: [
        { title: "Livre", class: "empty" },
        { title: "Reservada", class: "reserved" },
        { title: "Ocupada", class: "occupied" },
        { title: "Selecionada", class: "selected" },
      ] as SeatCaptionItem[],
    };
  },
});
