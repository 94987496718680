









































































import Vue, { PropType } from "vue";

import CardItem from "./MobileCardItem.vue";
import TCard from "@/components/core/TCard.vue";
import TCpfInput from "@/components/core/TCpfInput.vue";
import TTextInput from "@/components/core/TTextInput.vue";
import TEmailInput from "@/components/core/TEmailInput.vue";
import TPhoneInput from "@/components/core/TPhoneInput.vue";
import TAlert from "@/components/core/TAlert.vue";
import Sector from "@/types/Sector";
import Event from "@/types/Event";
import NominalTicket from "@/types/NominalTicket";

export default Vue.extend({
  name: "ValidationTicketCard",

  mounted() {
    this.setNameRules();
  },

  data() {
    return {
      nameRules: [] as Array<(v?: string) => boolean | string>,
    };
  },

  components: {
    CardItem,
    TCard,
    TCpfInput,
    TTextInput,
    TEmailInput,
    TPhoneInput,
    TAlert,
  },

  props: {
    value: {
      type: Array as PropType<NominalTicket[]>,
      default: () => [],
    },
    sector: {
      type: Object as PropType<Sector>,
      required: true,
    },
  },

  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    tickets: {
      get(): NominalTicket[] {
        return this.value;
      },
      set(value: NominalTicket[]) {
        this.$emit("input", value);
      },
    },
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
    event(): Event {
      return this.$store.state.events.selected;
    },
    hasBiometricAccess(): boolean {
      if (this.event.access_type === undefined) return false;

      return ["biometric", "hybrid"].includes(this.event.access_type);
    },
  },

  methods: {
    setNameRules() {
      this.nameRules = [
        (v: string) => !/\d/.test(v) || "O nome não pode conter números",
        (v?: string): boolean | string => !v || v.length > 2 || "O nome deve conter ao menos 3 letras",
      ] as Array<(v?: string) => boolean | string>;
    },
    validate() {
      return this.form.validate();
    },
  },
});
