














import Vue from "vue";

export default Vue.extend({
  name: "TTicketCounter",

  props: {
    value: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
});
